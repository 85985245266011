<template>
<div class="view-home">
  <div class="container questions-wrap pt-5">
    <!-- <div class="question-title">
      <h3 class="text-primary">{{ $t('about.title') }}</h3>
    </div> -->
    <div class="row mt-3">
      <div class="card test-about-card col-11 col-sm-6 mx-auto my-5">
        <div class="row">
          <!-- <div class="col-12 text-center pb-4">
            <img src="/images/tour2.png" alt="" width="140">
          </div> -->
          <div class="col-12">
            <p style="text-align: center;">
<!--              <img :src="quiz[`descr_image_${test_lang}`] ? quiz[`descr_image_${test_lang}`] : quiz.project_id === 1 ? '/images/1000-BALA-logo.png' : '/images/qabilet.png'" alt="">-->
              <img :src="'https://elbasyacademy.kz/uploads/' + quiz[`descr_image_${test_lang}`]" alt="" style="max-width: 100%;">
            </p>
            <h3 class="text-center">{{ quiz['title_' + test_lang] }}</h3>
            <div class="text-center">
              <div class="btn btn-light" v-if="quiz.questions"><img src="/images/about-test-button1.svg" alt="">{{ quiz.questions.length }} {{ $t('about.questions-text') + (test_lang === 'ru' ? pluralizeRus(quiz.questions.length, ['', 'а', 'ов']) : '') }}</div>
              <div class="btn btn-light"><img src="/images/about-test-button2.svg" alt="">{{ quiz.time }} минут</div>
            </div>
            <!-- <div class="description">
              {{ quiz['description_' + test_lang ]}}
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="question-content" v-html="quiz[`description_${test_lang}`]"></div>
    </div>
    <div class="row my-5">
      <div class="mx-auto">
        <router-link v-if="quiz.proctoring" to="/identification" tag="a" class="btn btn-default"><i class="el-icon-arrow-left mr-2"></i>{{ $t('about.back-button') }}</router-link>
        <a href="#" @click.prevent="openModal" class="btn btn-primary">{{ $t('about.begin-button') }}</a>
      </div>
    </div>
  </div>
  <b-modal id="confirm-modal" hide-footer hide-header>
    <div id="modal-wrapper">
      <div id="dialog" class="py-3">
        <button @click="$bvModal.hide('confirm-modal')" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
        <h3 class="mb-0">{{ $t('about.begin-modal-title') }}</h3>
        <div class="clearfix pt-3">
          <a @click="beginTest" class="btn btn-primary mx-2">{{ $t('about.begin-modal-button') }}</a>
          <a @click="$bvModal.hide('confirm-modal')" class="btn btn-outline-primary mx-2">{{ $t('about.begin-modal-cancel') }}</a>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>
<script>
export default {
  name: "About",
  components: {
  },
  methods: {
    pluralizeRus(n, forms) {
      return n % 10 == 1 && n % 100 != 11
          ? forms[0]
          : (n % 10 >= 2 && n % 10 <= 4
          && (n % 100 < 10
              || n % 100 >= 20) ? forms[1] : forms[2]);
    },
    openModal(){
      if (this.quiz.proctoring && !this.identification) {
        this.$router.push({ name: 'identification' })
      }
      this.$bvModal.show('confirm-modal');
    },
    beginTest() {
      if (this.quiz.type_id == 3) {
        this.$router.push({ path: '/collaboration' })
      } else if (this.quiz.type_id == 4) {
        this.$router.push({ path: '/complex-explanations' })
      } else {
        this.$router.push({ path: '/question/1' })
      }
    },
  },
  mounted() {
    if (localStorage.getItem('quiz')){
      this.quiz = JSON.parse(localStorage.getItem('quiz'));
      localStorage.setItem('display_results', this.quiz.display_results)
      console.log(localStorage.getItem('display_results'))
      if (this.quiz.type_id == 3) {
        this.quiz.questions.length = 14
        this.quiz.time = 20
      }
      if (this.quiz.type_id == 4) {
        this.quiz.questions.length = 9
      }
    } else {
      this.$router.push({ name: 'not-found' })
    }
    if (localStorage.getItem('identification')){
      this.identification = JSON.parse(localStorage.getItem('identification'));
    }
    if (localStorage.getItem('test_lang')){
      this.test_lang = localStorage.getItem('test_lang');
      this.$i18n.locale = this.test_lang;
    }
  },
  data(){
	  return {
	    quiz: {},
      identification: false,
      test_lang: 'ru',
      locale: localStorage.getItem('locale') ?? 'ru'
    }
  }
}
</script>
<style scoped>
.test-about-card img {
  max-height: 100px;
}
@media screen and (max-width: 678px) {
  .question-content img {
      width: 100%;
  }
}
</style>
